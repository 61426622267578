import React, {
  useRef, useEffect, useState, useMemo, useCallback,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import './Overview.scss';
import InfiniteScroll from 'react-infinite-scroller';
import { useInView } from 'react-intersection-observer';
import * as mailActionsFile from '../../actions/mail/mailActions';
import * as appStateActionFile from '../../actions/appState/appStateActions';
import DocumentList from './DocumentList';
import Toggle from '../toggle/Toggle';
import ScrollButton from '../scrollButton/ScrollButton';
import Preloader from '../preloader/Preloader';
import {
  getIdOfUser,
  getOfficeIdOfUser,
} from '../../helpers/accountHelpers';
import * as metricsReportActionsFile from '../../actions/metricsReport/metricsReportActions';
import * as annotationActionFile from '../../actions/annotation/annotationActions';
import * as orderActionFile from '../../actions/order/orderActions';
import * as branchOfficeActionFile from '../../actions/branchOffice/branchOfficeActions';
import * as machineActionFile from '../../actions/machine/machineActions';
import SearchBar from './searchBar/SearchBar';
import InfoIcon from '../infoIcon/InfoIcon';
import FilterBar from './filterBar/FilterBar';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useWebSocket from '../../hooks/useWebSocket';
import useQueue from '../../hooks/useQueue';
import useLocalStorage from '../../hooks/useLocalStorage';
import { Mixpanel } from '../../mixpanel/Mixpanel';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  annotationActions: bindActionCreators(annotationActionFile, dispatch),
  appStateActions: bindActionCreators(appStateActionFile, dispatch),
  mailActions: bindActionCreators(mailActionsFile, dispatch),
  metricsReportActions: bindActionCreators(metricsReportActionsFile, dispatch),
  orderActions: bindActionCreators(orderActionFile, dispatch),
  branchOfficeActions: bindActionCreators(branchOfficeActionFile, dispatch),
  machineActions: bindActionCreators(machineActionFile, dispatch),
});

const UserOverview = ({
  appStateReducer: {
    toggleValue,
  },
  branchOfficeReducer: {
    branchOffices,
    updateBranchOffice,
  },
  mailReducer: {
    mails,
    mailCount,
    lastMessageId,
    hasMoreArchivedMails,
    hasMoreOverviewMails,
    loading,
    unassignedId,
  },
  annotationActions,
  appStateActions,
  mailActions,
  metricsReportActions,
  orderActions,
  branchOfficeActions,
  machineActions,
  history,
}) => {
  const { t, i18n } = useTranslation('overview');
  const [inViewRef, inView] = useInView({ threshold: 0 });

  const toggleValues = [t('toggle.toggle1'), t('toggle.toggle2')];
  const branchOfficeTopic = 'branch-office';
  const overviewTopic = 'overview';

  const [archivePage, setArchivePage] = useState(0);
  const [overviewPage, setOverviewPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [lastId, setLastId] = useState(null);
  // const [delay, setDelay] = useState(false);
  const {
    storedValue: storedSelectedSites,
    setValue: setStoredSelectedSites,
  } = useLocalStorage('selectedSitesFilter', []);
  const { getValue: getActiveUser } = useLocalStorage('active-user');

  const filterBarRef = useRef();

  const { queueLength, addMessageToQueue, getMessageFromQueue } = useQueue();
  const {
    socketConnected,
    subscribeTopic,
    unsubscribeTopic,
    sendMessage,
  } = useWebSocket(addMessageToQueue);


  useOnClickOutside(filterBarRef, () => setShowFilters(false));

  useEffect(() => {
    annotationActions.clearAnnotations();
    mailActions.clearFetchedMail();
    orderActions.clearOrder();
    machineActions.clearMachineAttachmentsAndOptions();
    return () => {
      branchOfficeActions.clearBranchOffices();
    };
  }, []);

  useEffect(() => {
    if (queueLength > 0) {
      const message = getMessageFromQueue();
      if (message.topic.includes(branchOfficeTopic)) {
        branchOfficeActions.fetchBranchOfficeSuccess(message.body);
      } else if (message.topic.includes(overviewTopic)) {
        mailActions.clearOverViewByBranchId(parseInt(message.topic.split('/')[1], 10));
        mailActions.updateMailOverView(message.body, message.id);
      }
    }
  }, [queueLength]);

  useEffect(() => {
    if (socketConnected) {
      subscribeTopic(branchOfficeTopic);
    }
  }, [socketConnected]);

  useEffect(() => {
    const branchOfficeIds = branchOffices.map((office) => office.id);
    const filteredSites = storedSelectedSites.filter((site) => branchOfficeIds.includes(site));
    if (filteredSites.length) {
      setStoredSelectedSites(filteredSites);
    }
    if (branchOfficeIds.length && !storedSelectedSites.length) {
      setStoredSelectedSites(branchOfficeIds);
    }
  }, [branchOffices, branchOffices.length]);

  useEffect(() => {
    appStateActions.updateSubtitle(t('title'));
  }, [i18n.language]);

  useEffect(() => {
    if (updateBranchOffice) {
      sendMessage(branchOfficeTopic, updateBranchOffice);
    }
  }, [updateBranchOffice]);

  useEffect(() => {
    // Set page back to zero
    if (toggleValue == 0) setArchivePage(0);
    if (toggleValue == 1) setOverviewPage(0);
  }, [toggleValue, isSearching]);

  const handleSitesFilterChanged = (sites) => {
    setStoredSelectedSites(sites);
    mailActions.emptyMailList();
    setArchivePage(0);
    setOverviewPage(0);
  };

  useEffect(() => {
    if (storedSelectedSites.length > 0){
      mailActions.fetchDocumentCount(storedSelectedSites)
    }
  }, [storedSelectedSites])

  const loadMoreArchivedMails = useCallback(() => {
    if (hasMoreArchivedMails && storedSelectedSites.length > 0) {
      mailActions.fetchArchivedMails(storedSelectedSites, archivePage)
      setArchivePage(archivePage+1)
    } else {
      mailActions.fetchedAllArchivedMails();
    }
  }, [hasMoreArchivedMails, storedSelectedSites, archivePage]);

  const loadMoreOverviewMails = useCallback(() => {
    if (hasMoreOverviewMails && storedSelectedSites.length > 0) {
      mailActions.fetchOverviewMails(storedSelectedSites, overviewPage)
      setOverviewPage(overviewPage+1)
    } else {
      mailActions.fetchedAllOverviewMails();
    }
  }, [hasMoreOverviewMails, storedSelectedSites, overviewPage]);

  const handleUnassignOrder = async (mailId) => {
    const patchBody = [
      {
        op: 'replace',
        path: '/status',
        value: {
          id: 1,
        },
      },
      {
        op: 'remove',
        path: '/employee',
      },
    ];
    await mailActions.patchMail(mailId, patchBody);
    metricsReportActions.addToMetrics(getIdOfUser(getActiveUser()), mailId, 'UNASSIGN');
  };

  const handleMailClick = async (mail) => {
    Mixpanel.track('Document clicked');
    annotationActions.resetAnnotationsState();
    if (!mail.employee && !mail.processedAt) {
      const patchBody = [
        {
          op: 'replace',
          path: '/status',
          value: {
            id: 2,
          },
        },
        {
          op: 'replace',
          path: '/employee',
          value: {
            id: getIdOfUser(getActiveUser()),
          },
        },
      ];
      await mailActions.patchMail(mail.id, patchBody);
    }
    history.push({
      pathname: `/annotator/ner/${mail.id}`,
      state: { hasBeenAssigned: true },
    });

    mailActions.emptyMailList();
    setArchivePage(0)
    setOverviewPage(0)
  };

  // const createToggleView = useMemo(() => {
  //   return (<Toggle
  //     className="annotation-type"
  //     toggleValues={toggleValues}
  //     selectedValue={toggleValue}
  //   />)
  // }, [mailCount, toggleValues, toggleValue])

  const createMailOverview = useMemo(() => {
    if (toggleValues[toggleValue].toLowerCase() === toggleValues[1].toLowerCase()) {
      return (
        <>
          <InfiniteScroll
            pageStart={archivePage}
            loadMore={loadMoreArchivedMails}
            hasMore={hasMoreArchivedMails}
            loader={(
              <div className="loader-ellips" key="loader-ellips">
                <span className="loader-ellips__dot" />
                <span className="loader-ellips__dot" />
                <span className="loader-ellips__dot" />
                <span className="loader-ellips__dot" />
              </div>
            )}
          >
            <DocumentList
              ToggleValue={toggleValue}
              Mails={mails}
              OnRowClick={handleMailClick}
            />
          </InfiniteScroll>
          <ScrollButton shouldRender={!inView} />
        </>
      );
    }

    return (
      <>
        <InfiniteScroll
          pageStart={overviewPage}
          loadMore={loadMoreOverviewMails}
          hasMore={hasMoreOverviewMails}
          loader={(
            <div className="loader-ellips" key="loader-ellips">
              <span className="loader-ellips__dot" />
              <span className="loader-ellips__dot" />
              <span className="loader-ellips__dot" />
              <span className="loader-ellips__dot" />
            </div>
          )}
        >
          <DocumentList
            ToggleValue={toggleValue}
            Mails={mails}
            OnRowClick={handleMailClick}
            handleUnassignOrder={handleUnassignOrder}
          />
        </InfiniteScroll>
        <ScrollButton shouldRender={!inView} />
      </>
    );
  }, [mails, lastMessageId, toggleValue, hasMoreArchivedMails, hasMoreOverviewMails, unassignedId, inView, storedSelectedSites]);

  return (
    <div className="overview">
      <div className="ox-navigation-horizontal toggle-menu">
        <Toggle
          className="annotation-type"
          toggleValues={toggleValues}
          toggleCounts={mailCount}
          selectedValue={toggleValue}
        />
      </div>
      <div className="centered">
        <div className="annotated-documents">
          {loading ? <Preloader /> : createMailOverview}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserOverview);
