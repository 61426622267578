import sortBy from 'lodash/sortBy';
import * as mailTypes from '../actions/mail/mailTypes';

const initialState = {
  mails: [],
  mailCount: [0, 0],
  lastMessageId: null,
  activeMail: null,
  mailDocuments: [],
  activeDocument: 0,
  orderDocument: -1,
  loading: false,
  error: null,
  hasMoreArchivedMails: true,
  hasMoreOverviewMails: true,
  isLoading: false,
  unassignedId: null,
  assignedId: undefined,
};

const mailReducer = (state = initialState, action) => {
  switch (action.type) {
    case mailTypes.UPDATE_MAIL_OVERVIEW: {
      const { messageId } = action;
      const mails = [...state.mails, ...action.mails];
      const sortedMails = sortBy(mails, ['priorityIndex', 'timestamp']);

      return {
        ...state,
        loading: false,
        mails: sortedMails,
        lastMessageId: messageId,
      };
    }

    case mailTypes.CLEAR_OVERVIEW_BY_BRANCH_ID: {
      const { branchId } = action;
      const { mails } = state;

      const clearedMails = [...mails].filter((mail) => mail.branchOffice.id !== branchId);

      return { ...state, mails: clearedMails };
    }

    case mailTypes.SET_MAIL_COUNT:
      console.log('set mail count')
      console.log(action)
      return {...state, mailCount: action.data}
    case mailTypes.FETCH_ALL_MAILS:
      return { ...state, loading: true };
    case mailTypes.FETCH_ALL_MAILS_SUCCESS:
      return { ...state, loading: false, mails: [...state.mails, ...action.data] };
    case mailTypes.FETCH_ALL_MAILS_FAILED:
      return { ...state, loading: false, error: action.error };

    case mailTypes.PROCESS_MAILS_DONE:
      return { ...state, loading: false };
    case mailTypes.PROCESS_MAILS_LOADING:
      return { ...state, loading: true };

    case mailTypes.EXPAND_MAILS_LIST:
      return { ...state, loading: false, mails: state.mails.concat(action.data) };
    case mailTypes.RESET_MAILS_LIST:
      return { ...state, mails: [], hasMoreArchivedMails: true, hasMoreOverviewMails: true };
    case mailTypes.FETCHED_ALL_ARCHIVED_MAILS:
      return { ...state, hasMoreArchivedMails: false, loading: false };

    case mailTypes.FETCHED_ALL_OVERVIEW_MAILS:
      return { ...state, hasMoreOverviewMails: false, loading: false };


    case mailTypes.FETCH_MAIL_BY_ID:
      return {
        ...state,
        loading: true,
        activeMail: null,
        activeDocument: 0,
        orderDocument: null,
        hasMoreArchivedMails: true,
      };
    case mailTypes.CLEAR_FETCHED_MAIL: {
      return {
        ...state,
        activeMail: null,
        mailDocuments: [],
        activeDocument: 0,
        orderDocument: -1,
        assignedId: undefined,
      };
    }
    case mailTypes.FETCH_MAIL_BY_ID_SUCCESS: {
      const { data: mail } = action;
      const mailDocuments = [...mail.attachments]
        .map(({ entityAnnotations, ...keepAttrs }) => keepAttrs);
      const bodyIndex = mailDocuments.findIndex((mailItem) => (mailItem.name || '').toLowerCase() === 'body');
      if (bodyIndex !== 0) {
        // This will move the mail body to the first position
        mailDocuments.splice(0, 0, mailDocuments.splice(bodyIndex, 1)[0]);
      }
      const orderDocument = mailDocuments[0];
      const assignedId = mail.employee.id;

      delete mail.attachments;
      delete mail.orderValues;

      return {
        ...state,
        mails: [],
        loading: false,
        activeMail: mail,
        mailDocuments,
        orderDocument,
        assignedId,
      };
    }
    case mailTypes.FETCH_MAIL_BY_ID_FAILED:
      return { ...state, loading: false, error: action.error };

    case mailTypes.SET_MAIL_ACTIVE_DOCUMENT:
      return { ...state, activeDocument: action.data };

    case mailTypes.SEND_MAIL_BACK_TO_O365:
      return { ...state, data: action.data, loading: true };
    case mailTypes.SEND_MAIL_BACK_TO_O365_SUCCESS:
      return { ...state, loading: false };
    case mailTypes.SEND_MAIL_BACK_TO_O365_FAILED:
      return { ...state, loading: false, error: action.error };

    case mailTypes.SET_MAIL_STATUS_TODO:
      return { ...state, loading: false };
    case mailTypes.SET_MAIL_STATUS_TODO_SUCCESS: {
      const { mailId, statusId, userId } = action.data;
      const { mails } = state;
      let unassignedId = null;

      const foundMailIndex = mails.findIndex((mail) => mail.id === mailId);
      if (foundMailIndex !== -1 && statusId === 1) {
        mails[foundMailIndex].status = 'To do';
        mails[foundMailIndex].user_id = userId;
        mails[foundMailIndex].username = null;
        unassignedId = mails[foundMailIndex].id;
      }
      return {
        ...state, loading: false, mails, unassignedId,
      };
    }
    case mailTypes.SET_MAIL_STATUS_TODO_FAILED:
      return { ...state, loading: false };

    case mailTypes.CHANGE_ATTACHMENT_TYPE: {
      const { mailDocuments } = state;
      if (mailDocuments) {
        const { data: { documentId, documentTypes, typeId } } = action;
        const updatedAttachments = mailDocuments.map((attachment) => {
          if (attachment.id === documentId) {
            return { ...attachment, documentType: documentTypes.find((type) => type.id === typeId) };
          }

          // Don't allow multiple orders
          // if (typeId === 2 && attachment.documentType.id === 2) {
          //   return { ...attachment, documentType: documentTypes.find((type) => type.name === '/') };
          // }

          return attachment;
        });
        const activeDocument = updatedAttachments.findIndex((attachment) => attachment.id === documentId);
        return { ...state, mailDocuments: updatedAttachments, activeDocument };
      }

      return state;
    }

    case mailTypes.SEARCH_ALL_MAILS:
      return { ...state, loading: true };
    case mailTypes.SEARCH_ALL_MAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        mails: action.data,
        hasMoreArchivedMails: false,
      };
    case mailTypes.SEARCH_ALL_MAILS_FAILED:
      return {
        ...state,
        loading: false,
        mails: [],
        error: action.error,
        hasMoreArchivedMails: false,
      };

    case mailTypes.NO_MORE_ARCHIVED_MAILS:
      return { ...state, hasMoreArchivedMails: false, loading: false };

    default:
      return state;
  }
};

export { mailReducer as default };
