import axios from 'axios';
import { authenticationHeader, baseUrl } from '../constants/constants';

/**
 * We fetch the mail here to check if there is already a userId and processedBy property.
 * If this is true, the user can't open the mail.
 * @param mailId : string
 * @return boolean
 */
export const canOpenMail = (mailId, user) => axios.get(`${baseUrl}/mail/${mailId}`, authenticationHeader)
  .then((response) => {
    const { user_id: userId, processed_by: processedBy } = response.data[0];

    if (userId === user.id) {
      return true;
    }
    return !(userId && processedBy);
  }).catch((error) => {
    console.error('[Failed to check the mail status]', error);
    return false;
  });

/**
 * Search the possible contact persons in the database based on the senders email
 * If any are available, return the first one else null.
 * @param email : string
 * @return ContactPerson object
 */
export const queryPossibleContactPersons = (email) => axios
  .get(`${baseUrl}/contact-person/find-by-email?email=${email}`, authenticationHeader)
  .then((response) => {
    const foundPersons = response.data;
    if (foundPersons && foundPersons.length > 0) {
      return foundPersons[0];
    }
    return null;
  }).catch((error) => {
    console.error('[Failed to query the contact person]', error);
    return null;
  });

export const addNewContactPerson = async ({ firstName, lastName, email }) => {
  if (!firstName || !lastName || !email) {
    return null;
  }

  const possibleMatch = await queryPossibleContactPersons(email);
  if (possibleMatch && (possibleMatch.firstName || '').toLowerCase() === (firstName || '').toLowerCase()
    && (possibleMatch.lastName || '').toLowerCase() === (lastName || '').toLowerCase()) {
    return null;
  }
  const payload = {
    firstName,
    lastName,
    email,
    isUserAdded: true,
  };

  return axios.post(`${baseUrl}/contact-person`, payload, authenticationHeader);
};

export const sendMailBack = (mailId, employeeId) => {
  if (!mailId) {
    return false;
  }

  const data = {
    action: 'RETURN',
    employeeId,
  };

  return axios.post(`${baseUrl}/mail/${mailId}/action`, data, authenticationHeader)
    .then(() => true, (error) => {
      console.error('[Failed to send back the mail]', error);
      return false;
    });
};
