import React, { useState, useMemo } from 'react';
import './Header.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Avatar from '../avatar/Avatar';
import * as userActionFile from '../../actions/user/userActions';
import * as mailActionFile from '../../actions/mail/mailActions';
import * as branchOfficeActionFile from '../../actions/branchOffice/branchOfficeActions';
import {
  checkIfAdmin,
  getIdOfUser,
} from '../../helpers/accountHelpers';
import * as metricsReportActionsFile from '../../actions/metricsReport/metricsReportActions';
import SiteStatus from './siteStatus/SiteStatus';
import useLocalStorage from '../../hooks/useLocalStorage';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  branchOfficeActions: bindActionCreators(branchOfficeActionFile, dispatch),
  userActions: bindActionCreators(userActionFile, dispatch),
  mailActions: bindActionCreators(mailActionFile, dispatch),
  metricsReportActions: bindActionCreators(metricsReportActionsFile, dispatch),
});

const Header = ({
  appStateReducer,
  branchOfficeReducer: {
    branchOffices,
  },
  orderReducer: {
    activeOrder,
  },
  branchOfficeActions,
  userActions,
  metricsReportActions,
  history,
}) => {
  const { t } = useTranslation('menu');
  const [menuOpen, setMenuOpen] = useState(false);
  const { getValue: getActiveUser } = useLocalStorage('active-user', null);

  const logOut = () => {
    setMenuOpen(false);
    userActions.logoutUser();
    branchOfficeActions.clearBranchOffices();
    history.push('/login');
  };

  // const goToSettings = () => {
  //   setMenuOpen(false);
  //   history.push('/settings');
  // };

  const goToMetrics = () => {
    setMenuOpen(false);
    history.push('/metrics');
  };

  const handleBackClick = () => {
    if (activeOrder && activeOrder[0] && window.location.toString().includes('annotator')) {
      metricsReportActions.addToMetrics(getIdOfUser(getActiveUser()), activeOrder[0].mail_id, 'BACK');
    }
  };

  const renderSiteStatus = useMemo(() => {
    // if (branchOffices.length) {
    //   return branchOffices.map((branchOffice) => (<SiteStatus key={branchOffice.id} branchOffice={branchOffice} />));
    // }
    return null;
  }, [branchOffices, branchOffices.length]);

  const createUserAvatar = () => {
    if (getActiveUser()) {
      const userObject = getActiveUser();
      const { sub: username } = JSON.parse(atob(userObject['api-key'].split('.')[1]));
      if (username) {
        return (
          <div className="ox-dropmenu-wrapper" id="dox-drop-menu-example-2" aria-haspopup="true" aria-expanded="false">
            <input
              checked={menuOpen}
              onChange={() => setMenuOpen(!menuOpen)}
              type="checkbox"
              className="ox-dropmenu-toggle"
              id="toggle-dropmenu-2"
            />
            <label
              htmlFor="toggle-dropmenu-2"
              aria-label="Toggle dropmenu"
            >
              <Avatar name={username} disabledTooltip />
            </label>
            <div className="ox-dropmenu">
              <ul
                className="ox-dropmenu__list"
                tabIndex="-1"
                role="menu"
                aria-label="Overflow"
                data-floating-menu-direction="bottom"
              >
                {/* <li className="ox-dropmenu__option" role="presentation">
                  <button
                    type="button"
                    className="ox-dropmenu__option__button"
                    role="menuitem"
                    onClick={goToSettings}
                  >
                    <span className="ox-dropmenu__option__icon ox-icon ox-icon--settings" />
                    {t('settings')}
                  </button>
                </li> */}
                {
                      checkIfAdmin(getActiveUser()) ? (
                        <li className="ox-dropmenu__option" role="presentation">
                          <button
                            type="button"
                            className="ox-dropmenu__option__button"
                            role="menuitem"
                            onClick={goToMetrics}
                          >
                            <span className="ox-dropmenu__option__icon ox-icon ox-icon--graph" />
                            {t('metrics')}
                          </button>
                        </li>
                      ) : null
                  }
                <li
                  className="ox-dropmenu__option ox-dropmenu__option--error ox-dropmenu__option--separator"
                  role="presentation"
                >
                  <button
                    type="button"
                    className="ox-dropmenu__option__button"
                    role="menuitem"
                    onClick={logOut}
                  >
                    <span className="ox-dropmenu__option__icon ox-icon ox-icon--power" />
                    {t('logout')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <header className="ox-layout__header navbar" role="banner">
      <div className="ox-header-container header-container">
        <NavLink className="ox-header__site-name logo" onClick={handleBackClick} to="/">
          <img height="30px" alt="logo" src="/assets/logo_vanmarcke.jpeg" />
          <i>{appStateReducer.title}</i>
          <span className="spacer" />
          <p>{appStateReducer.subtitle}</p>
        </NavLink>
        <div className="ox-layout__primary-navigation-wrapper header-group-wrapper">
          { renderSiteStatus }
          { createUserAvatar() }
        </div>
      </div>
    </header>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
