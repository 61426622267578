import axios from 'axios';
import * as mailTypes from './mailTypes';
import { authenticationHeader, baseUrl, patchAuthenticationHeader } from '../../constants/constants';
import { invalidApiKey } from '../user/userActions';
import { history } from '../../components/App'; // eslint-disable-line
import { addErrorMessage } from '../feedback/feedbackActions';
import { fetchOrderSuccess } from '../order/orderActions';
import { overwriteAnnotations } from '../annotation/annotationActions';
import { tokenIsValid } from '../../helpers/accountHelpers';

export const updateMailOverView = (mails, messageId) => ({ type: mailTypes.UPDATE_MAIL_OVERVIEW, mails, messageId });
export const clearOverViewByBranchId = (branchId) => ({ type: mailTypes.CLEAR_OVERVIEW_BY_BRANCH_ID, branchId });

export const setMailCount = (data) => ({type: mailTypes.SET_MAIL_COUNT, data});

export const fetchAllMailsSuccess = (data) => ({ type: mailTypes.FETCH_ALL_MAILS_SUCCESS, data });
const fetchAllMailsFailed = (error) => ({ type: mailTypes.FETCH_ALL_MAILS_FAILED, error });
export const fetchAllMailsLoading = () => ({ type: mailTypes.FETCH_ALL_MAILS });

export const clearFetchedMail = () => ({ type: mailTypes.CLEAR_FETCHED_MAIL });

const appendToMails = (data) => ({ type: mailTypes.EXPAND_MAILS_LIST, data });
const fetchedAllArchivedMails = () => ({ type: mailTypes.FETCHED_ALL_ARCHIVED_MAILS });

const fetchedAllOverviewMails = () => ({ type: mailTypes.FETCHED_ALL_OVERVIEW_MAILS });

export const emptyMailList = () => ({ type: mailTypes.RESET_MAILS_LIST });

const fetchMailByIdSuccess = (data) => ({ type: mailTypes.FETCH_MAIL_BY_ID_SUCCESS, data });
const fetchMailByIdFailed = (error) => ({ type: mailTypes.FETCH_MAIL_BY_ID_FAILED, error });
const fetchMailByIdLoading = () => ({ type: mailTypes.FETCH_MAIL_BY_ID });

const setMailStatusLoading = () => ({ type: mailTypes.SET_MAIL_STATUS_LOADING });
const setMailStatusSuccess = () => ({ type: mailTypes.SET_MAIL_STATUS_SUCCESS });
const setMailStatusFailed = (error) => ({ type: mailTypes.SET_MAIL_STATUS_FAILED, error });

export const changeActiveDocument = (data) => ({ type: mailTypes.SET_MAIL_ACTIVE_DOCUMENT, data });

export const changeAttachmentType = (data) => ({ type: mailTypes.CHANGE_ATTACHMENT_TYPE, data });

const searchAllMails = () => ({ type: mailTypes.SEARCH_ALL_MAILS });
const searchAllMailsSuccess = (data) => ({ type: mailTypes.SEARCH_ALL_MAILS_SUCCESS, data });
const searchAllMailsFailed = (error) => ({ type: mailTypes.SEARCH_ALL_MAILS_FAILED, error });

export const noMoreArchivedMails = () => ({ type: mailTypes.NO_MORE_ARCHIVED_MAILS });

export const fetchDocumentCount = (branchOfficeIds) => (dispatch) => {
  let url = `${baseUrl}/document/count/${branchOfficeIds}`;
  // Get document count
  return axios.get(url, authenticationHeader).then((response) => {
    const NumberOfDocuments = response.data.NumberOfDocuments
    const NumberOfApprovedDocuments = response.data.NumberOfApprovedDocuments
    dispatch(setMailCount([NumberOfDocuments, NumberOfApprovedDocuments]));
  }, (error) => {
    // Logout user
    if (error.response.status && error.response.status === 401) {
      if (!tokenIsValid()) {
        dispatch(invalidApiKey());
        history.push('/login');
      }
    }
    // Set error message
    setMailCount([0, 0])
  });
}

export const patchMail = (mailId, patchBody) => (dispatch) => {
  dispatch(setMailStatusLoading());
  const promise = axios.patch(`${baseUrl}/document/${mailId}`, patchBody, patchAuthenticationHeader);
  promise.then(() => {
    dispatch(setMailStatusSuccess());
  }, (error) => {
    dispatch(setMailStatusFailed(error));
  });

  return promise;
};

export function fetchMailById(mailId, entityTypes) {
  return (dispatch) => {
    dispatch(fetchMailByIdLoading());
    return axios.get(`${baseUrl}/document/${mailId}`, authenticationHeader)
      .then(({ data }) => {
        dispatch(overwriteAnnotations(
              data.attachments,
              entityTypes,
            ));
        dispatch(fetchOrderSuccess([...data.orderValues]));
        dispatch(fetchMailByIdSuccess(data));
        return data;
      }, (error) => {
        dispatch(fetchMailByIdFailed(error));
      });
  };
}

export const fetchOverviewMails = (branchOfficeIds, page) => (dispatch) => {
  // Set page and size of the query
  let url = `${baseUrl}/document/overview/${branchOfficeIds}/?page=${page}`;
  if (page == 0) dispatch(fetchAllMailsLoading());

  // Get overview documents
  return axios.get(url, authenticationHeader).then((response) => {
    if (response.data.length > 0) {
      dispatch(appendToMails(response.data));
    } else {
      dispatch(fetchedAllOverviewMails());
    }
  }, (error) => {
    // Logout user
    if (error.response.status && error.response.status === 401) {
      if (!tokenIsValid()) {
        dispatch(invalidApiKey());
        history.push('/login');
      }
    }
    // Set error message
    dispatch(fetchAllMailsFailed(error));
    dispatch(addErrorMessage(error.message));
    dispatch(fetchedAllOverviewMails());
  });
}

export const fetchArchivedMails = (branchOfficeIds, page) => (dispatch) => {
  // Set page and size of the query
  let url = `${baseUrl}/document/archived/${branchOfficeIds}/?page=${page}`;
  if (page == 0) dispatch(fetchAllMailsLoading());

  // Get overview documents
  return axios.get(url, authenticationHeader).then((response) => {
    if (response.data.length > 0) {
      dispatch(appendToMails(response.data));
    } else {
      dispatch(fetchedAllArchivedMails());
    }
  }, (error) => {
    // Logout user
    if (error.response.status && error.response.status === 401) {
      if (!tokenIsValid()) {
        dispatch(invalidApiKey());
        history.push('/login');
      }
    }
    // Set error message
    dispatch(fetchAllMailsFailed(error));
    dispatch(addErrorMessage(error.message));
    dispatch(fetchedAllArchivedMails());
  });
}

export function searchMails(queryString, branchId, type = 'new') {
  return (dispatch) => {
    dispatch(searchAllMails());
    // TODO: set baseUrl to url => effective crudAPI
    const archived = type === 'archived';
    // const url = 'http://localhost:8081/api/v1';
    return axios.get(`${baseUrl}/document/${branchId}/search?query=${queryString}&archived=${archived}`, authenticationHeader)
      .then((response) => {
        dispatch(searchAllMailsSuccess(response.data));
      }, (error) => {
        if (error.response.status && error.response.status === 401) {
          if (!tokenIsValid()) {
            dispatch(invalidApiKey());
            history.push('/login');
          }
        }
        dispatch(searchAllMailsFailed(error));
        dispatch(addErrorMessage(error.response.data.error.message));
      });
  };
}

export const reportMail = (reason, extraInfo, mailId) => () => {
  const data = {
    extraInfo,
    reason,
    mailId,
  };

  return axios.post(`${baseUrl}/report`, data, authenticationHeader).then((result) => result).catch((error) => {
    console.error('[FAILED TO REPORT MAIL]: ', mailId);
    return error;
  });
};
