import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import './EntityList.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as annotationActionFile from '../../../actions/annotation/annotationActions';
import * as entityActionFile from '../../../actions/entity/entityActions';
import * as orderActionsFile from '../../../actions/order/orderActions';
import EntityRow from './EntityRow';
import { containsOnly, sortJsonArrayByKey } from '../../../helpers/arrayHelpers';
import { filterMachinesExtras, ATTACHMENTS, OPTIONS } from '../../../helpers/machineHelpers';
import GroupHeader from './entityGroupComponents/GroupHeader';
import AddGroupButton from './entityGroupComponents/AddGroupButton';
import { determineEntityRowProperties } from '../../../helpers/determineEntityRowProperties';
import MachineModal from './modals/MachineModal';
import {
  ATTACHMENTS_ENTITY_ID,
  COMMENT_FIELD_ENTITY_IDS,
  MACHINE_ENTITY_ID,
  MACHINE_TYPE_ENTITY_ID,
  OPTIONS_ENTITY_ID,
} from '../../../constants/constants';
import { mapCodeToDescription } from '../../../helpers/codeMapperHelpers';

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  annotationActions: bindActionCreators(annotationActionFile, dispatch),
  entityActions: bindActionCreators(entityActionFile, dispatch),
  orderActions: bindActionCreators(orderActionsFile, dispatch),
});

const EntityList = ({
  entityReducer: {
    selectedEntity: {
      id: selectedEntityId,
      index: selectedEntityIndex,
    },
  },
  mailReducer: {
    activeMail,
    activeDocument,
    loading: mailIsLoading,
  },
  orderReducer: {
    activeOrder,
    activeTabIndex,
  },
  customerReducer: {
    customers,
  },
  extraOptionsReducer: {
    options: extraOptions,
  },
  machineReducer: {
    machines,
    machineOptions,
    machineAttachments,
  },
  configMapReducer: {
    configMap,
  },
  disabled,
  annotatingDisabled,
  orderActions,
  annotationActions,
  form,
}) => {
  const [filledStore, setFilledStore] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentMachineIndex, setCurrentMachineIndex] = useState(1);
  const handleModalClicked = (value, machineIndex) => {
    setCurrentMachineIndex(machineIndex);
    setOpenModal(value);
  };

  const { t } = useTranslation('annotationView');

  const { reset } = form;

  const addItem = useCallback((indexToAdjust, entityId) => {
    const optionsItem = activeOrder.find((item) => item.entityId === entityId);

    if (optionsItem) {
      const newArray = optionsItem.value[indexToAdjust];
      newArray.push(null);
      orderActions.changeVariable({
        orderEntityId: optionsItem.entityId,
        indexToAdjust,
        value: newArray,
        shouldFocusInput: true,
      });
    }
  }, [activeOrder]);

  const canAddOptions = useCallback((index) => !(activeOrder.map((item) => {
    if (item.entityId === MACHINE_ENTITY_ID) {
      return machines.some((machine) => machine.machkode === parseInt(item.value[index][0] || '-1', 10));
    }
    if (item.entityId === OPTIONS_ENTITY_ID) {
      const extras = filterMachinesExtras(index, OPTIONS).map((extra) => extra.description);
      return containsOnly(extras, item.value[index]);
    }
    return true;
  }).includes(false)), [activeOrder]);

  const canAddAttachments = useCallback((index) => !activeOrder.map((item) => {
    if (item.entityId === MACHINE_ENTITY_ID) {
      return machines.some((machine) => machine.machkode === parseInt(item.value[index][0] || '-1', 10));
    }
    if (item.entityId === ATTACHMENTS_ENTITY_ID) {
      const extras = filterMachinesExtras(index, ATTACHMENTS).map((extra) => extra.description);
      return containsOnly(extras, item.value[index]);
    }
    return true;
  }).includes(false), [activeOrder]);

  const hasErrorMessage = (errorMessage) => errorMessage !== undefined;

  const customerInfo = useMemo(() => {
    if (customers.length > 0 && activeOrder.length > 0) {
      const customerNumber = activeOrder.find((item) => item.entityId === 1);
      const foundCustomer = customers.find((customer) => customer.customer_no === (customerNumber.value['1'][0] || '').toString());
      if (foundCustomer) {
        return { name: foundCustomer.customer_name, vat: foundCustomer.vat_number };
      }
    }

    return { name: null, vat: null };
  }, [activeOrder, customers]);

  const buildGroupedEntities = useCallback((tabIndex) => {
    const sortedConfigMap = sortJsonArrayByKey(configMap);
    return sortJsonArrayByKey(sortedConfigMap[tabIndex].groupBlocks).map((groupItem) => {
      const entityTypes = sortJsonArrayByKey(groupItem.entityTypes).map((entityItem) => {
        let numberOfMachines = 1;

        if (groupItem.name.toLowerCase() === 'machine') {
          const check = activeOrder.find((orderItem) => orderItem.entityId === MACHINE_TYPE_ENTITY_ID);
          numberOfMachines = (check && check.value) ? Object.keys(check.value).length : 1;
        }
        const orderValue = activeOrder.find((orderItem) => orderItem.entityId === entityItem.id);

        let returnEntity = { ...entityItem };
        if (orderValue) {
          const values = {};
          Object.keys(orderValue.value).forEach((key) => {
            values[key] = orderValue ? mapCodeToDescription(orderValue.value[key], orderValue.entityId) : [entityItem.defaultValue];
          });

          if (Object.keys(values).length !== numberOfMachines) {
            for (let i = Object.keys(values).length - 1; i < numberOfMachines; i += 1) {
              values[[i + 1]] = [entityItem.defaultValue];
            }
          }

          returnEntity = {
            ...returnEntity,
            value: values,
            entityId: orderValue.entityId,
            orderId: orderValue.id,
            featureDisabled: entityItem.disabled,
          };
        } else {
          const value = {};
          for (let i = 0; i < numberOfMachines; i += 1) {
            value[[i + 1]] = [entityItem.defaultValue];
          }

          returnEntity = {
            ...returnEntity,
            value,
            featureDisabled: entityItem.disabled,
          };
        }
        return returnEntity;
      });
      return {
        ...groupItem,
        entityTypes,
      };
    });
  }, [configMap, activeOrder]);

  const groupedEntities = useMemo(() => {
    if ((configMap && configMap[activeTabIndex].groupBlocks.length) && activeOrder) {
      return buildGroupedEntities(activeTabIndex);
    }
    return [];
  }, [configMap, activeOrder, activeTabIndex]);

  useEffect(() => {
    // This monster of a useEffect wil prefill all values in the store
    if (!mailIsLoading && activeMail && !filledStore && (configMap && configMap.length)) {
      // Check how many entities the store should have
      const entityCount = configMap.map((tab) => tab.groupBlocks
        .map((configMapItem) => configMapItem.entityTypes)).flat(2).length;
      // Check if the count of the active ordes is not equal to the expected length
      if (activeOrder.length !== entityCount) {
        setFilledStore(true);
        // Loop over the entity map, build the grouped entities and set item in the store
        configMap.forEach((mapItem) => {
          buildGroupedEntities(mapItem.index).map((group) => group.entityTypes).flat(1).forEach((entity) => {
            Object.keys(entity.value).forEach((index) => {
              orderActions.changeVariable({
                orderEntityId: entity.id,
                indexToAdjust: index,
                value: entity.value[index],
              });
            });
          });
        });
      }
    }
  }, [configMap, activeOrder, activeMail, mailIsLoading]);

  const entityItems = useCallback((entityTypes, index) => entityTypes.map((entity) => {
    if (entity.showInModal) {
      return null;
    }
    const isHighlighted = selectedEntityId == null ? true : entity && selectedEntityId === entity.id && selectedEntityIndex === index.toString();
    const isTextArea = entity.componentType.toLowerCase() === 'textarea';
    // The reason for this destruction is a huge switch-case that can become even larger.
    const combinedParameters = {
      disabled: (entity.featureDisabled || disabled),
      entity,
      machines,
      index,
      activeOrder,
      machineOptions,
      canAddOptions,
      machineAttachments,
      canAddAttachments,
      customers,
      customerInfo,
      extraOptions,
    };
    const {
      errorMessage,
      isOptionsEntity,
      isAttachmentsEntity,
      options,
      needsToBeDisabled,
      removeEnabled,
      titleName,
      returnOption,
      extraInfo,
    } = determineEntityRowProperties(combinedParameters, t);
    const containerClassName = `entity-row-container ${hasErrorMessage(errorMessage) ? 'ox-is-invalid' : ''}`;

    let entityValue = entity.value[index];
    if (typeof entityValue === 'undefined') entityValue = new Array(1).fill(null);
    const hasMultipleAnnotations = entityValue.length > 1;
    return (
      <div
        className={`${containerClassName}${entity.componentType}`}
        key={`entity-row-container-${activeTabIndex}-${entity.id}-${index}`}
      >
        <EntityRow
          key={`entity-row-${entity.id}-${index}`}
          id={entity.id}
          index={index}
          entity={entity}
          value={entityValue}
          name={titleName}
          isTextArea={isTextArea}
          options={options}
          selected={isHighlighted}
          disabled={needsToBeDisabled}
          removeEnabled={removeEnabled}
          required={entity.required}
          annotatingDisabled={annotatingDisabled}
          hasMultipleAnnotations={hasMultipleAnnotations}
          errorMessage={errorMessage}
          returnOption={returnOption}
          extraInfo={extraInfo}
          form={form}
          featureDisabled={entity.featureDisabled}
        />
      </div>
    );
  }), [groupedEntities, activeOrder, selectedEntityId, selectedEntityIndex, disabled, annotatingDisabled, machineOptions, customers]);

  const removeGroupBlock = (config, keyValue, groupName) => {
    reset();
    setCurrentMachineIndex(1);
    orderActions.removeGroupFromOrder(config, keyValue, groupName);
    annotationActions.removeAnnotationsForGroup(config, keyValue, groupName);
  };

  // Determine action for the component and render the GroupHeader component
  const renderGroupHeader = useCallback((entityValueKeys, groupedEntity, key) => {
    // const entityName = groupedEntity.name.toLowerCase();
    let deleteAction = null;
    let count = 0;
    let keyValueToShow = ''; // only show key if the length >= 2
    if (groupedEntity.multipleGroupBlocks && entityValueKeys && entityValueKeys.length >= 2) {
      deleteAction = !disabled ? removeGroupBlock : null;
      keyValueToShow = key;
    }

    if (groupedEntity.entityTypes.filter((entity) => entity.showInModal).length > 0) {
      count = groupedEntity.entityTypes.filter((entity) => entity.showInModal)
        .map((entity) => entity.value[key]).flat(1)
        .filter((value) => value && value !== 'false').length;
    }
    return (
      <GroupHeader
        key={`group-header-${activeTabIndex}-${groupedEntity.name}`}
        id={groupedEntity.id}
        groupName={groupedEntity.name}
        keyValue={keyValueToShow}
        configMap={configMap}
        deleteAction={deleteAction}
        extraAction={handleModalClicked}
        count={count}
      />
    );
  }, [disabled, configMap]);

  // Decide which button should be rendered based on the groupedEntityName
  const renderOptions = useCallback((entityValueKeys, groupedEntity, index) => {
    if (groupedEntity.multipleGroupBlocks && entityValueKeys.length - 1 === index && !disabled) {
      return (
        <AddGroupButton
          text={t(`addButtons.${groupedEntity.name.toLowerCase()}`)}
          action={orderActions.addGroupToOrder}
          configMap={configMap}
          groupName={groupedEntity.name}
        />
      );
    }
    return null;
  }, [configMap, disabled]);

  const entityRows = useMemo(() => groupedEntities.map((groupedEntity) => {
    if (groupedEntity.multipleGroupBlocks || activeTabIndex === 1) {
      const entityValueKeys = Object.keys(groupedEntity.entityTypes[0].value);
      return entityValueKeys.map((key, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`entity-group-${activeTabIndex}-${groupedEntity.index}-${index}`}>
          {renderGroupHeader(entityValueKeys, groupedEntity, key)}
          {entityItems(groupedEntity.entityTypes, key)}
          {renderOptions(entityValueKeys, groupedEntity, index)}
        </React.Fragment>
      ));
    }
    return (
      <React.Fragment key={`entity-group-${activeTabIndex}-${groupedEntity.index}`}>
        {renderGroupHeader([], groupedEntity, null)}
        {entityItems(groupedEntity.entityTypes, 1)}
      </React.Fragment>
    );
  }, [groupedEntities, activeOrder, selectedEntityId, configMap, activeTabIndex]));

  const clientInfo = useMemo(() => {
    if (activeDocument && activeDocument.meta_data) {
      const {
        order: {
          RentalOrderRequests: [
            { CustomerNumber },
          ],
        },
      } = activeDocument.meta_data;

      return (
        <React.Fragment key="entity-row-client">
          <p className="group-title client-title">Client</p>
          <p className="client-number">{CustomerNumber}</p>
        </React.Fragment>
      );
    }

    return null;
  }, [activeDocument]);

  const machineModal = useMemo(() => (
    <MachineModal
      currentMachineIndex={currentMachineIndex}
      disabled={disabled}
      form={form}
      groupedEntities={groupedEntities}
      openModal={openModal}
      setOpenModal={handleModalClicked}
    />
  ), [groupedEntities, currentMachineIndex, openModal]);


  const key = (activeDocument || {}).internal_uuid || 'empty-list';
  return (
    <div className="entity-list" key={`entity-list-${key}`}>
      {machineModal}
      {clientInfo}
      {entityRows}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityList);
